const CLUSTER_STUDIO_HOST_REGEX = /studio.([a-z0-9-]+)\.cresta\.ai/;

export function getClusterFromHost(): string {
  const currentHost = window.location.host;
  const match = currentHost.match(CLUSTER_STUDIO_HOST_REGEX);
  if (match) {
    return match[1];
  }
  // Default chat-staging for localhost.
  if ((currentHost.includes('localhost') && process.env.REACT_APP_LOCALHOST_PRODUCTION?.toLowerCase() !== 'true')
    || currentHost.includes('staging')) {
    return 'chat-staging';
  }
  // Production default is chat-prod.
  return 'chat-prod';
}

export function isProd() {
  const url = window.location.host;
  if ((url.includes('localhost') && process.env.REACT_APP_LOCALHOST_PRODUCTION?.toLowerCase() !== 'true')
    || url.includes('staging')) {
    return false;
  }
  return true;
}
